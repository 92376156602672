import { render, staticRenderFns } from "./perform-arrival-control-for-driver.vue?vue&type=template&id=36b9d044&scoped=true&"
import script from "./perform-arrival-control-for-driver.vue?vue&type=script&lang=js&"
export * from "./perform-arrival-control-for-driver.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b9d044",
  null
  
)

export default component.exports