<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Perform arrival control for driver</h4>

        <div class="row">
          <div class="col-sm-6" v-for="control in controls">

            <div class="form-group">
              <label>{{control.label}}</label>
              <input type="text" class="form-control" >
              <span v-if="control.required_for_arrival === 'yes'" class="text-danger "> This field is required</span>
              <span v-else class="text-black-50"> This field is optional</span>

            </div>

          </div>
        </div>

      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Attach arrival control images</h4>



      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "perform-arrival-control-for-driver",
  data(){
    return {
      controls: []
    }
  },
  computed:{
    arrivalControls() {
      const c = this.$store.getters.GET_ARRIVAL_RENTAL_CONTROLS;
      c.forEach(e => {
        this.controls.push({...e})
      })
      return c
    }
  },
  created() {
    this.$store.dispatch('getRentalControls').then(() => {
      const id = this.$route.params['id']
      this.$store.dispatch('fetchRentalDetail', id)
    })

  }
}
</script>

<style scoped>

</style>